import { Controller } from "stimulus"
import geojsonhint from "@mapbox/geojsonhint"
import DynamicMap from "../src/components/DynamicMap"

export default class extends Controller {
  static targets = ["input", "preview"]

  get input() {
    return this.inputTarget.value
  }

  get geojson() {
    return JSON.parse(this.input)
  }

  connect() {
    this.mapPreview = new DynamicMap(
      this.data.get("accessToken"),
      this.previewTarget
    )
  }

  validate() {
    let errors = geojsonhint.hint(this.input)
    this.mapPreview.clearTrailPath()
    if (errors.length) {
      this.toggleError(true)
    } else {
      this.toggleError(false)
      this.mapPreview.addTrailPath(this.geojson)
    }
  }

  toggleError(state) {
    let $input = $(this.inputTarget)
    $input
      .parent()
      .toggleClass("field--error", state)
      .next(".field__error")
      .toggle(state)
    $input
      .parents("form")
      .find("button[type='submit']")
      .prop("disabled", state)
  }
}
