import DynamicMap from "../src/components/DynamicMap"
import StaticMap from "../src/components/StaticMap"

DynamicMap.initClusterMaps(document.querySelectorAll(".js--clusterMap"))

for (let element of document.querySelectorAll(".js--map")) {
  let options = {
    center: [
      parseFloat(element.dataset.centerLongitude),
      parseFloat(element.dataset.centerLatitude),
    ],
    zoom: element.dataset.zoom || 10,
  }
  let map = new DynamicMap(element.dataset.accessToken, element, options)

  if (element.dataset.geojson) {
    let geojson = JSON.parse(element.dataset.geojson)
    map.onLoad(() => map.addTrailPath(geojson))
  }

  let staticMapElement = $(element).next(".js--staticMap")
  if (staticMapElement) {
    map.onRender(() => {
      staticMapElement.attr("src", StaticMap.fromDynamicMap(map).url())
    })
  }
}
