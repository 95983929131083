/* eslint no-console:0 */

import "core-js/stable";
import "regenerator-runtime/runtime";
import "../polyfills/position_sticky"
import "../src/maps"
import "../src/toggle"

// Initialize Stimulus
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))
