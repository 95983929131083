import mapboxStatic from "@mapbox/mapbox-sdk/services/static"
import _isEmpty from "lodash/isEmpty"

export default class StaticMap {
  static fromDynamicMap(dynamicMap) {
    return new StaticMap(
      dynamicMap.accessToken,
      dynamicMap.center,
      dynamicMap.zoom,
      dynamicMap.data
    )
  }

  // Public: Render static maps and assign `src` attribute for each element.
  //
  // elements - Array of DOM `img` elements
  //
  // TODO: Consider refactoring to not rely on gon (similar to
  // `DynamicMap.initClusterMaps`).
  //
  // Returns nothing.
  static initMaps(elements) {
    for (let element of elements) {
      let map = new StaticMap(
        gon.mapbox_access_token,
        gon.map.center,
        gon.map.zoom,
        gon.map.path_data
      )
      element.src = map.render()
    }
  }

  // Public: Initialize a static map.
  //
  // accessToken - Mapbox access token String
  // center      - Array of coordinates or GeoJSON Object
  // zoom        - Number representing zoom level (default: 5)
  // feature     - A GeoJSON Object
  //
  // Returns a map instance.
  constructor(accessToken, center, zoom, feature) {
    this.accessToken = accessToken
    this.center = center
    this.zoom = zoom
    this.feature = feature
  }

  // Public: Generate the URL for a static map.
  //
  // Returns a URL String.
  url() {
    let request = this._staticClient().getStaticImage({
      ownerId: "mapbox",
      styleId: "outdoors-v10",
      width: 1000,
      height: 460,
      coordinates: this.center,
      zoom: this.zoom,
      overlays: this._formatOverlays(this.feature),
    })
    return `${request.url()}?access_token=${this.accessToken}`
  }

  // Internal: Get the Mapbox static image client configured with an
  // access token.
  //
  // Returns a Mapbox client.
  _staticClient() {
    return mapboxStatic({ accessToken: this.accessToken })
  }

  // Internal: Format overlay data.
  //
  // feature - GeoJSON feature
  //
  // Returns an Array.
  _formatOverlays(feature) {
    if (_isEmpty(feature)) {
      return []
    } else {
      return [{ geoJson: feature }]
    }
  }
}
